<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>

        <div class="container">
            <div class="action">
                <el-button type="primary" @click="add()">新增</el-button>
            </div>
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" :cell-style="cellstyle" stripe border :data="pageInfo.list" class="el-table" style="margin-top: 50px;">
                <el-table-column label="id" prop="id" align="center" width="80"></el-table-column>
                <el-table-column label="消息模板" prop="name" align="center" width="150"></el-table-column>
                <el-table-column label="消息类型" prop="template_name" align="center" width="150"></el-table-column>
                <el-table-column label="消息内容" prop="content" align="center"></el-table-column>
                <el-table-column label="发送人" prop="pusher" align="center" width="150"></el-table-column>
                <el-table-column label="状态" prop="state" align="center" width="120">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.state === 2">已发送</el-tag>
                        <el-tag type="info" v-if="scope.row.state === 1">未发送</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button type="text" icon="el-icon-delete" @click="remove(scope.row.id)">删除</el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            发送</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 上线、下线提示框 -->
            <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
                <div class="del-dialog-cnt">{{ confirmContent }}</div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="confirmVisible = false">取 消</el-button>
                    <el-button type="primary" @click="stateData">确 定</el-button>
                </span>
            </el-dialog>
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 新增 编辑 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="600px" v-if="addVisible" class="dialog_box">
            <el-form ref="form" :model="form" label-width="140px" :rules="rules" label-position="left">
                <el-form-item label="消息内容" prop="content">
                    <el-input type="textarea" autosize placeholder="请输入消息内容" v-model="form.content">
                    </el-input>
                </el-form-item>
                <el-form-item label="消息模板" prop="template_id">
                    <el-select v-model="form.template_id" placeholder="请选择消息模板">
                        <el-option v-for="(items,index) in tempelteList" :key="index" :label="items.template_name" :value="items.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="发送人" prop="pusher">
                    <el-select v-model="form.pusher" placeholder="请选择发送人">
                        <el-option v-for="(items,index) in peopleList" :key="index" :label="items.name" :value="items.ty"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="发送时间" prop="time">
                    <el-date-picker v-model="form.time" type="datetime" placeholder="选择日期时间" :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import { request } from '@/common/request';
import { deal } from '@/common/main';
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination
    },
    data() {
        return {
            isSync: true,
            message: [],
            peopleList: [
                {
                    ty: 0,
                    name: '全部'
                },
                {
                    ty: 1,
                    name: '店主'
                },
                {
                    ty: 2,
                    name: '店员'
                },
                {
                    ty: 3,
                    name: '会员'
                },
                {
                    ty: 4,
                    name: '指定人'
                },
            ],
            tempelteList: [],
            rules: {
                content: [{
                    required: true,
                    message: '消息内容不能为空',
                    trigger: 'blur'
                }],
                // template_id: [{
                //     required: true,
                //     message: '消息模板不能为空',
                //     trigger: 'blur'
                // }],
                pusher: [{
                    required: true,
                    message: '发送人不能为空',
                    trigger: 'blur'
                }],
                time: [{
                    required: true,
                    message: '时间不能为空',
                    trigger: 'blur'
                }],
            },
            menuList: ['消息', '发送消息'],
            loading: false,
            confirmVisible: false,
            confirmContent: '',
            queryParams: {
                page: 1,
                pagesize: 10,
            },
            pageInfo: {},
            addVisible: false,
            form: {
            },
            title: '新增',
            mode: 'add',
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now()
                        || time.getTime() > Date.now() + 3600 * 1000 * 24 * 30;
                }
            }
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        getconf() {
            request.get('/common/conf').then(ret => {
                if (ret.code == 1) {
                    this.message = ret.data.message
                    console.log(22222222222, this.message)
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否未发送?';
            } else {
                this.confirmContent = '确定是否发送?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.confirmVisible = true;
        },
        cellstyle() {
            return 'text-align: center';
        },
        // 获取所有消息模板
        loadTempleteData() {
            request.get('/common/message/template/list', { page: 1, pagesize: -1, state: 2 }).then(ret => {
                if (ret.code == 1) {
                    this.tempelteList = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        loadData() {
            request.get('/common/send/message/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //新增
        add() {
            this.loadTempleteData()
            this.mode = 'add';
            this.title = '新增';
            this.form = {
                state: 2
            };
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        //编辑
        edit(index, row) {
            this.loadTempleteData()
            this.mode = 'edit';
            this.title = '编辑';
            // this.getconf()
            this.form = {}
            this.current = row.id;
            const item = this.pageInfo.list[index];
            this.form = Object.assign({}, item);
            this.addVisible = true;

        },
        // 定时发送
        saveTimeData() {
            let _this = this
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        let param = Object.assign({}, this.form);
                        var url = '/common/message/weixin/edit';
                        setInterval(
                            function () {
                                request.post(url, param).then(ret => {
                                    if (ret.code == 1) {
                                        this.addVisible = false;
                                        this.loadData();
                                        this.$message.success('编辑成功');
                                        this.$refs.form.clearValidate()
                                    } else {
                                        this.$message.error(ret.msg);
                                    }
                                });
                            }, 600000)

                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };


        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        let param = Object.assign({}, this.form);
                        var url = this.mode == 'add' ? '/common/send/message/add' : '/common/send/message/edit';
                        request.post(url, param).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') + '成功');
                                this.$refs.form.clearValidate()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 确定下线
        stateData() {
            request.post('/common/send/message/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 删除
        remove(id) {
            request.post('/common/send/message/remove', { id: id }).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('删除成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        }
    }
};
</script>
<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}
.dialog_box >>> .el-textarea {
    width: 80%;
}
.dialog_box >>> .el-input--small .el-input__inner {
    width: 148%;
}
.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
</style>